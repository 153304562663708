import React from 'react';
import '../App.css';

const NavBar = () => {
  return (
    <div>
      <p id="logo">tella.friend</p>
    </div>
  )
}

export default NavBar;